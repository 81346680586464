.binge-search-logo {
    height: 40px;
}

.myaccount-icon {
    font-size: 30px;
    color: var(--ion-color-dark);
}

.myaccount-icon-rotate {
    /* border: 2px dotted wheat; */
    border-radius: 50%;
    /* animation: icon-rotation 5s infinite linear; */
}

@keyframes icon-rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}

.popover-content {
    top: 3em !important;
    width: 170px !important;
}

.app-title {
    font-family: monospace;
    font-weight: bold;
    font-size: 20px;
}

.app-subtitle {
    font-family: monospace;
    font-size: 14px;
}

@media (max-width: 300px) {
    .app-title {
        font-size: 14px;
    }

    .app-subtitle {
        font-size: 10px;
    }
}

@media (min-width: 300px) and (max-width: 430px) {
    .app-title {
        font-size: 17px;
    }

    .app-subtitle {
        font-size: 13px;
    }
}


.detail-section {
    color: var(--ion-color-tertiary-shade);
    display: block;
    margin: 14px 0px;
    font-size: larger;
    font-family: auto;
    font-weight: 600;
    font-family: monospace;
    text-transform: capitalize;
}
ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

.sc-ion-label-ios-h .item .sc-ion-label-md-h {
  white-space: normal;
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 20px;
  font-weight: 600;
  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 15px;
  margin-bottom: 18px;
  color: #757575;
  min-height: 26px;
}

ion-menu.md ion-item {
  /* margin: -5px; */
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
  margin-right: 15px;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
  white-space: normal;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item ion-icon {
  font-size: 20px;
  color: #73849a;
}

ion-menu.ios ion-item .selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-list#labels-list ion-list-header {
  /* margin-bottom: 8px; */
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;
  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}

ion-title {
  font-size: 10px;
  font-family: sans-serif;
  font-weight: bold;
}

.darkModeIcon {
  padding-bottom: 65px;
}

.store-img {
  width: 150px;
  padding-inline-end: 5px;
}

.welcome-message {
  padding: 15px;
  overflow: hidden;
  white-space: nowrap;
  animation: typeWriter 3s linear 0s infinite alternate;
}

@keyframes typeWriter {
  from {
    width: 0;
  }
  to {
    width: 250px;
  }
}

.ion-fab {
  width: 40px;
  height: 40px;
  margin-left: -5px;
}

.ion-fab-list {
  margin: -8px 38px;
}

.ion-fab-lang {
  margin-left: 1px;
}

.version-label {
  font-size: 10px;
  color: var(--ion-color-medium);
}

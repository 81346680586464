.searchbar-design {
    --icon-color: var(--ion-color-primary);
    --placeholder-color: var(--ion-color-primary-shade);
    --placeholder-opacity: 0.8;
    --border-radius: 4%;
    transition: outline 3s ease-in-out;
}

.searchbar-design :focus {
    outline: 1px var(--ion-color-primary) solid;
}

.search-index {
    opacity: 0.8;
}

.search-img {
    width: 200px;
}

.search-container {
    border: 2px solid beige;
}

.search-popover .popover-content {
    top: 40px;
    width: 400px;
}

@media (max-width: 500px) {
    .search-popover .popover-content {
        top: 38px;
        width: 200px;
    }
}
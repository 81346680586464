.profile-img {
    width: 100px;
}

.verified-icon {
    position: fixed;
    font-size: 18px;
    color: var(--ion-color-success);
}

.reviews-title {
    font-size: 18px;
    color: brown;
    text-transform: capitalize;
}

.reviews-type {
    color: grey;
    font-size: 10px;
}

.form-div {
    margin: 10px;
}

.form-label {
    display: inline-flex;
}

.select-dropdown {
    height: 35px;
    border-radius: 5px;
}

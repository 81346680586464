/* .ion-loader-design {
    --background: none;
    --spinner-color: var(--ion-color-primary);
    --backdrop-opacity: 0.7;
    --height: 25px;
    --width: 25px;
} */

.ad-banner {
    height: 200px;
    border: 1px dotted grey;
    margin: 10px;
}

.swiper-slide img {
    position: relative;
    height: 150px;
    width: 135px;
}

.error-box {
    border: 2px solid var(--ion-color-tertiary-shade);
    padding: 15px;
    margin: 50px;
}

.error-box ul {
    list-style-type: square;
}

.source-style {
    color: var(--ion-color-primary-shade);
    font-size: 12px;
    text-align: center;
}

.round-corner {
    border-radius: 50%;
    border: 1px solid var(--ion-color-tertiary-shade);
    border-radius: 50%;
    box-shadow: 1px 1px 1px 2px var(--ion-color-primary);
}

.left-corner {
    border-top-left-radius: 50px;
}

.square-corner {
    border-radius: 5%;
}

.slide-numbering {
    position: absolute;
    z-index: 100;
    font-size: 80px;
    font-family: fantasy;
    margin-left: -25px;
    margin-top: 60px;
    color: #3963ffd9;
}

.section {
    text-align: center;
    color: var(--ion-color-tertiary-shade);
    display: block;
    margin: 14px 0px;
    font-size: larger;
    font-family: auto;
    font-weight: 600;
    font-family: monospace;
    text-transform: capitalize;
}

.specific-section {
    text-align: center;
    color: var(--ion-color-tertiary-shade);
    display: block;
    margin: 14px;
    font-size: larger;
    font-family: auto;
    font-weight: 600;
    font-family: monospace;
    text-transform: capitalize;
}

.watchlist-icon {
    position: fixed;
    font-size: 18px;
    color: var(--ion-color-primary-shade);
}

.explore-icon {
    font-size: 25px;
    margin-bottom: 5px;
}

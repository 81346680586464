.star-widget {
    margin-right: 70px;
}

.star-widget input {
    display: none;
}

.star-widget label {
    font-size: 30px;
    color: var(--ion-color-medium-tint);
    padding: 5px;
    float: right;
    transition: all 0.2s ease;
}

input:not(:checked) ~ label:hover,
input:not(:checked) ~ label:hover ~ label {
    color: var(--ion-color-primary);
}
input:checked ~ label {
    color: var(--ion-color-primary);
}
input#rate-5:checked ~ label {
    color: var(--ion-color-primary);
    text-shadow: 0 0 20px #952;
}
#rate-1:checked ~ ion-row header:before {
    content: "Crap! I don't like it 😠";
}
#rate-2:checked ~ ion-row header:before {
    content: "Meh! It was OK 😒";
}
#rate-3:checked ~ ion-row header:before {
    content: "Good! I just like it 😄";
}
#rate-4:checked ~ ion-row header:before {
    content: "Niche! I recommend it 😎";
}
#rate-5:checked ~ ion-row header:before {
    content: "Awesome! I love it 😍";
}

ion-row header {
    font-size: 15px;
    color: var(--ion-color-tertiary);
    margin: 5px 0 10px 0;
    text-align: center;
    transition: all 0.2s ease;
}

ion-textarea {
    border: 1px solid var(--ion-color-medium-shade);
    padding: 5px;
}

ion-textarea:focus {
    border: 1px solid var(--ion-color-primary-shade);
}

.detail-img {
    width: 100%;
}

.share {
    vertical-align: super;
}

.rating-box {
    border: 5px dotted var(--ion-color-primary);
    border-radius: 50%;
    background: var(--ion-color-primary);
    color: var(--ion-color-warning-tint);
    font-weight: 500;
}

.row-padding {
    margin: 10px 0px;
}

.text-headers {
    color: var(--ion-color-tertiary);
    text-transform: capitalize;
    font-size: 15px;
    font-family: monospace;
    font-weight: bold;
}

.reviews-block {
    font-size: 15px;
    margin: 10px 5px;
}

.review-name {
    margin: 5px 5px;
    color: var(--ion-color-success-shade);
    font-size: 12px;
}

.review-rating {
    border-radius: 50%;
    background: var(--ion-color-primary);
    color: var(--ion-color-warning-tint);
    font-weight: 500;
    font-size: 15px;
    padding: 5px;
    margin-right: 10px;
}

.review-comments {
    width: 100%;
    padding: 5px;
    margin: 5px;
    background: var(--ion-color-light);
}

.movie-title {
    text-align: center;
    color: var(--ion-color-tertiary-shade);
    display: block;
    font-size: larger;
    font-family: auto;
    font-weight: 600;
    text-transform: capitalize;
    font-family: monospace;
}

.movie-subtitle {
    color: darkslategrey;
    font-size: 13px;
    text-transform: capitalize;
    font-family: monospace;
    font-weight: bold;
}

.tag {
    background: var(--ion-color-success-tint);
    border-radius: 10%;
    margin: 2px 2px;
    padding: 2px 2px;
    text-transform: capitalize;
}

.hide {
    display: none;
}

.clear {
    float: none;
    clear: both;
}

.rating {
    /* padding-right: 15vw; */
    unicode-bidi: bidi-override;
    direction: rtl;
    text-align: center;
    position: relative;
}

.rating > label {
    float: right;
    padding: 0;
    margin: 0;
    position: relative;
    width: 1.1em;
    cursor: pointer;
    color: var(--ion-color-medium-tint);
    font-size: 25px;
}

.rating > input.radio-btn:checked ~ label {
    color: transparent;
}

.rating > input.radio-btn:checked ~ label:before,
.rating > input.radio-btn:checked ~ label:before {
    content: "\2605";
    position: absolute;
    left: 0;
    color: var(--ion-color-primary);
}

.rating-align {
    margin-top: 20px;
}

@media (max-width: 576px) {
    .rating {
        padding-right: 30vw;
    }
}

.recommended-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.text-style {
    color: var(--ion-color-primary-shade);
    font-size: 12px;
    text-align: center;
    margin: 5px 0px 8px 0px;
}
.footer-label {
    text-align: center;
}

ion-footer {
    font-size: 12px;
}


.whatsapp-logo-icon {
    font-size: 15px;
    color: var(--ion-color-success-shade);
}

.facebook-logo-icon {
    font-size: 15px;
    color: #3b5998;
}

.twitter-logo-icon {
    font-size: 15px;
    color: var(--ion-color-secondary-shade);
}

.linkedin-logo-icon {
    font-size: 15px;
    color: #0e76a8;
}

.instagram-logo-icon {
    font-size: 15px;
    color: var(--ion-color-danger-shade);
}

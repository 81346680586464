.facebook-logo-icon-1 {
    font-size: 25px;
    color: #3b5998;
}

.twitter-logo-icon-1 {
    font-size: 25px;
    color: var(--ion-color-secondary-shade);
}

.linkedin-logo-icon-1 {
    font-size: 25px;
    color: #0e76a8;
}

.instagram-logo-icon-1 {
    font-size: 25px;
    color: var(--ion-color-danger-shade);
}
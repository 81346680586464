.app {
    height: 100%;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    margin-top: -35px;
}

.login-container {
    text-align: center;
    color: var(--ion-color-primary);
    margin-top: 40px;
    background: #00c2cb;
    padding: 20px 34px 70px;
    border-radius: 20px;
    border-bottom-left-radius: 250px;
    border-bottom-right-radius: 250px;
}

.login-container > header {
    font-size: 1rem;
    font-weight: 700;
    margin: 0 auto 20px auto;
    position: relative;
    height: 30px;
    width: 250px;
    overflow: hidden;
}

.header-headings {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    position: absolute;
    width: 100%;
    -webkit-transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.header-headings > span {
    margin: 3px 0;
}
.header-headings.sign-in {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
}
.header-headings.sign-up {
    -webkit-transform: translateY(-27px);
    transform: translateY(-27px);
}
.header-headings.forgot {
    -webkit-transform: translateY(-53px);
    transform: translateY(-53px);
}

.options {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 350px;
    margin-bottom: 15px;
}

@media screen and (max-width: 380px) {
    .options {
        width: 100%;
    }
}

.options > ion-col {
    cursor: pointer;
    opacity: 0.5;
    font-weight: 400;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.options > ion-col:hover {
    opacity: 1;
}
.options > ion-col.active {
    opacity: 1;
    font-weight: bolder;
    font-size: large;
}

.account-form {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    margin-top: 15px;
}

.account-form-fields {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    overflow: hidden;
    -webkit-transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.account-form-fields.sign-in {
    max-height: 175px;
}
.account-form-fields.sign-up {
    max-height: 250px;
}
.account-form-fields.forgot {
    max-height: 250px;
}
.account-form-fields > input {
    border: 1px solid grey;
    margin-bottom: 10px;
    padding: 10px;
    font-size: 1rem;
    font-family: sans-serif;
    color: #000;
    border-radius: 5px;
}
.account-form-fields > input::-webkit-input-placeholder {
    color: #aaa;
}
.account-form-fields > input::-moz-placeholder {
    color: #aaa;
}
.account-form-fields > input:-ms-input-placeholder {
    color: #aaa;
}
.account-form-fields > input::-ms-input-placeholder {
    color: #aaa;
}
.account-form-fields > input::placeholder {
    color: #aaa;
}
.account-form-fields > input::-webkit-input-placeholder {
    color: #aaa;
}
.account-form-fields > input:-ms-input-placeholder {
    color: #aaa;
}
.account-form-fields > input:focus {
    outline: none;
}

.btn-submit-form {
    border: 0;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    padding: 15px 0;
    margin-top: 20px;
    border-radius: 5px;
    color: #fff;
    font-size: 1rem;
    font-family: sans-serif;
    background: var(--ion-color-tertiary);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.13);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.btn-submit-form:hover {
    background: var(--ion-color-tertiary);
}
.btn-submit-form:active,
.btn-submit-form:focus {
    outline: none;
    background: var(--ion-color-tertiary);
}

.error {
    padding: 3px;
    color: var(--ion-color-danger);
    font-size: 12px;
}

/* ion-slides {
    height: 30vh;
    --bullet-background-active: red;
    --progress-bar-background-active: yellow;
    --scroll-bar-background-active: green;
} */

.slideshow-details {
    display: inline-block;
    position: absolute;
    font-size: 10px;
    margin-left: 90px;
    padding: 2px;
    background: var(--ion-color-primary-shade);
    color: var(--ion-color-primary-contrast);
}

.slideshow-images {
    width: 400px;
}

.results-title {
    font-size: 15px;
    color: var(--ion-color-primary);
    text-transform: capitalize;
}

.results-type {
    font-size: 13px;
    color: var(--ion-color-medium);
    text-transform: capitalize;
}

.results-source {
    font-size: 13px;
    color: var(--ion-color-primary-shade);
    text-transform: capitalize;
}

@media (min-width: 768) {
    .slideshow-images {
        width: 500px;
    }
}
